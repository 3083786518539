
<div class="main-header-banner">
  <div class="header-banner">
    <img src="assets/image/compare-bg.jpg">
  </div>
</div>
<div class="apply-empl-pro-sec apply-dasbord">
  <div class="col-12 no-padding">
    <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="apply-emp-profile-box card">

       <!-- Page Content -->
        <div class="container reviews" *ngIf="uReviews">

          <div class="card-columns">

            <div *ngIf="uReviews.jobs.length == 0" >
                <h4>Aucun widget audio pour le moment... Rendez-vous dans l'onglet campagnes pour créer votre premier widget !</h4>
            </div>
            <div class="card rev" *ngFor="let widget of uReviews.jobs">
              <div class="row" id="rev-{{widget.id}}">
                <div class="col-md-4 col-12">
                <div class="qr">
                  <canvas width="76" title="partager ce widget" height="76" id="qr-{{widget.id}}"></canvas>
                </div>
                <a href="{{widget.widgetUrl}}" target="_blank">
                <img class="card-img-top mp3-cover" title="Cliquez sur l'image pour ouvrir ce widget !" src="{{uReviews.company.background}}" alt="Card image cap">
                </a>
                <div class="card-block">
                  <h4 class="card-title">{{widget.name}}</h4>
                  <!--starting user review-->
                  <div *ngFor="let userrev of widget.userJobs; let index = index;" id="urev-{{widget.id}}-{{index}}">
                    <p class="card-text">Le {{userrev.created_at}} - {{userrev.firstName ? userrev.firstName : ''}}
                      <span class = "{{userrev.rating > 0 ? 'fa' : 'far'}} fa-star"></span>
                      <span class = "{{userrev.rating > 1 ? 'fa' : 'far'}} fa-star"></span>
                      <span class = "{{userrev.rating > 2 ? 'fa' : 'far'}} fa-star"></span>
                      <span class = "{{userrev.rating > 3 ? 'fa' : 'far'}} fa-star"></span>
                      <span class = "{{userrev.rating > 4 ? 'fa' : 'far'}} fa-star"></span> :
                    </p>
                    <div class="mp3-audio">
                      <audio controls class="mp3-auido">
                        <source src="{{uReviews.company.storageUrl + userrev.audio}}" type="audio/mp3">
                        Your browser does not support the audio element.
                    </audio>
                    </div>
                  </div>
                  <div style="height: 3rem; text-align:center; width:100%;">
                    <button *ngIf="widget.userJobs.length > 1" (click)="toggleUReviews(widget.id, widget.userJobs.length)" class="btn btn-main btn-rounded mt-2">Dérouler/Masquer les audios</button>
                  </div>
                  <!--ending user review-->
                </div>
                </div>
                <div class="col-md-8 col-12">
                  <div class="col-12">
                    <h4>Top Thématiques</h4>
                  </div>
                  <div class="col-12 row">
                    <table class="col-12 mt-2 th-tags tendanceTable" *ngIf="widget.cloudTags && widget.cloudTags.length > 0">
                      <tr>
                        <td class="tendanceTitle">
                          <h5>- Positif : </h5>
                        </td>
                        <td>
                          <span
                            *ngFor="let tag of widget.cloudTags | filterCloudTag:'+'"
                            class="badge badge-pill badge-light">
                            {{tag && tag.tag}}
                          </span>
                          <!--<span class="badge badge-pill badge-light">Prix (13%)</span>
                          <span class="badge badge-pill badge-light">Service Client (9%)</span>
                          <span class="badge badge-pill badge-light">Expérience Mémorable (5%)</span>
                          <span class="badge badge-pill badge-light">Localisation (6%)</span>-->
                        </td>
                      </tr>
                      <tr>
                        <td class="tendanceTitle">
                          <h5>- Négatif : </h5>
                        </td>
                        <td>
                          <span
                          *ngFor="let tag of widget.cloudTags | filterCloudTag:'-'"
                          class="badge badge-pill badge-light-n">
                            {{tag && tag.tag}}
                          </span>
                          <!--<span class="badge badge-pill badge-light-n">Confus (9%)</span>
                          <span class="badge badge-pill badge-light-n">Risque (5%)</span>
                          <span class="badge badge-pill badge-light-n">Ennuyeux (13%)</span>
                          <span class="badge badge-pill badge-light-n">Mauvaise expérience (6%)</span>-->
                        </td>
                      </tr>
                      <tr>
                        <td class="tendanceTitle">
                          <h5>- Neutre : </h5>
                        </td>
                        <td>
                          <span *ngFor="let tag of widget.cloudTags | filterCloudTag:'n'"
                          class="badge badge-pill badge-light-0">
                            {{tag && tag.tag}}
                          </span>
                          <!--<span class="badge badge-pill badge-light-0">Bien (6%)</span>
                          <span class="badge badge-pill badge-light-0">Amélioration (6%)</span>
                          <span class="badge badge-pill badge-light-0">Qualité (9%)</span>
                          <span class="badge badge-pill badge-light-0">Bénéfice (13%)</span>-->
                        </td>
                      </tr>
                    </table>
                    <table class="col-12 mt-4 th-resume">
                      <tr>
                        <th class="txt">Texte</th>
                        <th class="cd">Coordonnées</th>
                        <th class="rt">Note</th>
                      </tr>
                      <tr *ngFor="let userrev of widget.userJobs; let index = index;" id="urevtable-{{widget.id}}-{{index}}">
                        <td>{{userrev.audioText ? userrev.audioText : ''}}</td>
                        <td>
                          {{userrev.firstName ? 'Nom: '+userrev.firstName : ''}}<br/>
                          {{(userrev.email && !userrev.email.includes('gotaf.fr')) ? 'Email: '+userrev.email : ''}}
                        </td>
                        <td class="rt-cell">
                          <span class = "{{userrev.rating > 0 ? 'fa' : 'far'}} fa-star"></span>
                          <span class = "{{userrev.rating > 1 ? 'fa' : 'far'}} fa-star"></span>
                          <span class = "{{userrev.rating > 2 ? 'fa' : 'far'}} fa-star"></span>
                          <span class = "{{userrev.rating > 3 ? 'fa' : 'far'}} fa-star"></span>
                          <span class = "{{userrev.rating > 4 ? 'fa' : 'far'}} fa-star"></span>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--<div class="card-columns">
            <div class="card rev">
              <div class="">
                <div class="qr">
                  <canvas width="76" title="partager ce widget" height="76" id="qr-2"></canvas>
                </div>
                <a href="https://api.gotaf.fr/sonorize/show-widget/ref_monabanq/103017" target="_blank">
                <img class="card-img-top mp3-cover" title="Cliquez sur l'image pour ouvrir ce widget !" src="https://www.stockvault.net/data/2017/08/19/238266/thumb16.jpg" alt="Card image cap">
                </a>
                <div class="card-block">
                  <h4 class="card-title">Que vous inspire Stonehenge ?</h4>

                  <p class="card-text">Le 23/02/2023 - Jean-Muriel
                    <span class = "fa fa-star checked"></span>
                    <span class = "fa fa-star checked"></span>
                    <span class = "fa fa-star checked"></span>
                    <span class = "fa fa-star checked"></span>
                    <span class = "fa fa-star checked"></span> :
                  </p>
                  <div class="mp3-audio">
                    <audio controls class="mp3-auido">
                      <source src="http://blog.landr.com/wp-content/uploads/2017/07/LANDR-WAVY-neon.wav" type="audio/mpeg">
                      <source src="audio/lp_78_am_guitar_funk_reggae.mp3" type="audio/mpeg">
                      <source src="audio/lp_78_am_guitar_funk_reggae.ogg" type="audio/ogg">
                      <source src="audio/lp_78_am_guitar_funk_reggae.wav" type="audio/wav">
                      Your browser does not support the audio element.
                  </audio>
                  </div>

                  <p class="card-text">Le 18/02/2023 - Antoine
                    <span class = "fa fa-star checked"></span>
                    <span class = "fa fa-star checked"></span>
                    <span class = "fa fa-star checked"></span>
                    <span class = "fa fa-star checked"></span>
                    <span class = "far fa-star"></span> :
                  </p>
                  <div class="mp3-audio">
                    <audio controls class="mp3-auido">
                      <source src="http://blog.landr.com/wp-content/uploads/2017/07/LANDR-WAVY-neon.wav" type="audio/mpeg">
                      <source src="audio/lp_78_am_guitar_funk_reggae.mp3" type="audio/mpeg">
                      <source src="audio/lp_78_am_guitar_funk_reggae.ogg" type="audio/ogg">
                      <source src="audio/lp_78_am_guitar_funk_reggae.wav" type="audio/wav">
                      Your browser does not support the audio element.
                  </audio>
                  </div>
                </div>
              </div>
            </div>

            <div class="card rev">
              <div class="">
                <img class="card-img-top mp3-cover" src="https://www.stockvault.net/data/2017/09/30/239530/thumb16.jpg" alt="Card image cap">
                <div class="card-block">
                  <h4 class="card-title">Lovekickone</h4>
                  <p class="card-text">neon</p>
                  <div class="mp3-audio">
                    <audio controls class="mp3-auido">
                      <source src="http://blog.landr.com/wp-content/uploads/2017/07/LANDR-WAVY-neon.wav" type="audio/mpeg">
                      <source src="audio/lp_78_am_guitar_funk_reggae.mp3" type="audio/mpeg">
                      <source src="audio/lp_78_am_guitar_funk_reggae.ogg" type="audio/ogg">
                      <source src="audio/lp_78_am_guitar_funk_reggae.wav" type="audio/wav">
                      Your browser does not support the audio element.
                  </audio>
                  </div>
                </div>
              </div>
            </div>

            <div class="card rev">
              <div class="">
                <img class="card-img-top mp3-cover mp3-hover" src="https://www.stockvault.net/data/2017/08/19/238266/thumb16.jpg" alt="Card image cap">
                <div class="card-block">
                  <h4 class="card-title">Lovekickone</h4>
                  <p class="card-text">neon</p>
                  <div class="mp3-audio">
                    <audio controls class="mp3-auido">
                      <source src="http://blog.landr.com/wp-content/uploads/2017/07/LANDR-WAVY-neon.wav" type="audio/mpeg">
                      <source src="audio/lp_78_am_guitar_funk_reggae.mp3" type="audio/mpeg">
                      <source src="audio/lp_78_am_guitar_funk_reggae.ogg" type="audio/ogg">
                      <source src="audio/lp_78_am_guitar_funk_reggae.wav" type="audio/wav">
                      Your browser does not support the audio element.
                  </audio>
                  </div>
                </div>
              </div>
            </div>

            <div class="card rev">
              <div class="">
                <img class="card-img-top mp3-cover" src="https://www.stockvault.net/data/2017/09/30/239530/thumb16.jpg" alt="Card image cap">
                <div class="card-block">
                  <h4 class="card-title">Lovekickone</h4>
                  <p class="card-text">neon</p>
                  <div class="mp3-audio">
                    <audio controls class="mp3-auido">
                      <source src="http://blog.landr.com/wp-content/uploads/2017/07/LANDR-WAVY-neon.wav" type="audio/mpeg">
                      <source src="audio/lp_78_am_guitar_funk_reggae.mp3" type="audio/mpeg">
                      <source src="audio/lp_78_am_guitar_funk_reggae.ogg" type="audio/ogg">
                      <source src="audio/lp_78_am_guitar_funk_reggae.wav" type="audio/wav">
                      Your browser does not support the audio element.
                  </audio>
                  </div>
                </div>
              </div>
            </div>

        </div>-->

      </div>

    </div>
  </div>
</div>

